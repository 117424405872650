// Импортируем другие js-файлы
(function($) {
  "use strict";

  /* meanmenu */
  $("#mobile-menu").meanmenu({
    meanMenuContainer: ".mobile-menu",
    meanScreenWidth: "767"
  });

  /* slider-active */
  $(".slider-active").owlCarousel({
    loop: true,
    nav: false,
    navText: [
      '<i class="fa fa-angle-left"></i>',
      '<i class="fa fa-angle-right"></i>'
    ],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  });

  // portfolio // interactive_gallery_block
  $(".grid").imagesLoaded(function() {
    $(".portfolio-area").on("click", "button", function() {
      var filterValue = $(this).attr("data-filter");
      $grid.isotope({ filter: filterValue });
    });

    var $grid = $(".grid").isotope({
      itemSelector: ".grid-item",
      percentPosition: true,
      masonry: {
        // use outer width of grid-sizer for columnWidth
        columnWidth: ".grid-item"
      }
    });
  });

  //for filter menu active class // interactive_gallery_block
  $(".portfolio-menu button").on("click", function(event) {
    $(this)
      .siblings(".active")
      .removeClass("active");
    $(this).addClass("active");
    event.preventDefault();
  });

  /* image-link */
  $(".image-link").magnificPopup({
    type: "image",
    gallery: {
      enabled: true
    }
  });

  /* slider-block-active */
  $(".blog-active").owlCarousel({
    loop: true,    //Зацикливаем слайдер
	nav: false, //Отключение навигации
	margin: 0, //Отступ от элемента справа в 50px
    dots: false, //Отображение навигационных «точек»
	autoplay: true, //Автозапуск слайдера
	smartSpeed:1000, //Время движения слайда
	autoplayTimeout: 3000, //Время смены слайда
	autoplayHoverPause: false, //Останавливает автопроигрывание если навести мышкой (hover) на элемент
    navText: [
      '<i class="fa fa-angle-left"></i>',
      '<i class="fa fa-angle-right"></i>'
    ],
	responsiveClass: true, //Объект содержит в себе настройки для адаптивности. Если установить значение в false — поддержка адаптивности отключается.
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      1000: {
        items: 3
      }
    }
  });

  /* testimonial-active */
  $(".testimonial-active").owlCarousel({
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  });
})(jQuery);
// base.js